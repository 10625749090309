<template>
    <div class="gst-event-similar-attractions pr-0">
        <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
        <div
            v-else-if="items.length"
            class="mr-n4 mr-md-0">
            <div class="d-flex flex-row mx-0 pb-4 pr-4 pr-4">
                <h5 class="gst-event-similar-attractions__title flex-grow-1">
                    {{ $t('title') }}
                </h5>
            </div>
            <div class="gst-top-locations__card_container d-flex">
                <div
                    v-for="item in items"
                    :key="item.id"
                    class="gst-top-locations__item">
                    <AttractionCardVariant4
                        :item="item"
                        height="144px"
                        width="144px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import AttractionCardVariant4 from '@core/shared/components/attraction/AttractionCardVariant4';
    import DataLoading from '@core/shared/components/loading/DataLoading';

    export default {
        name: 'EventSimilarAttractions',
        components: {
            AttractionCardVariant4,
            DataLoading,
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.similarAttractions'
        },
        computed: {
            ...mapState( {
                similarAttractions: state => state.user.analytics.similarAttractions,
                loading:            state => state.user.analytics.loading,
            } ),
            items( ) {
                return this.similarAttractions.slice( 0, 6 );
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-top-locations__card_container {
        height: 194px;
        padding: 0 16px;
        margin: 0 -16px;
        gap: 16px;
        overflow: scroll;
    }

    .gst-top-locations__item {
        height: fit-content;
        max-width: 304px !important;
        box-shadow: 0 16px 16px -8px rgba( 0, 0, 0, 0.12 ), 0 6px 4px -4px rgba( 0, 0, 0, 0.06 ), 0 40px 34px -16px rgba( 0, 0, 0, 0.08 ), 0 0 0 1px rgba( 0, 0, 0, 0.05 );
    }

    .gst-top-locations__card_container::-webkit-scrollbar {
        display: none;
    }

    .gst-event-similar-attractions__title {
        line-height: line-height( 'xxxxl' );
        font-size: font-size( 'xxxl' );
        font-weight: font-weight( 'large' );
    }

    .gst-attraction-card-variant-4 {
        position: relative;
        max-width: 100%;

        &.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
            border-radius: border-radius( 'xxs' ) !important;
        }
    }
</style>